import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import SavePage from './pages/SavePage';
import ViewPage from './pages/ViewPage';
import React from 'react';
import HomePage from './pages/HomePage';
import DeletePage from './pages/DeletePage';

function App() {
  return (
    
    <div className="App">
      <Router>
    <nav>
      <ul>
      <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/view">View</Link>
        </li>
        <li>
          <Link to="/save">Save</Link>
        </li>
        <li>
          <Link to="/delete">Delete</Link>
        </li>
      </ul>
    </nav>

    <Switch>

    <Route path="/delete">
        <DeletePage />
      </Route>      
      <Route path="/save">
        <SavePage />
      </Route>      
    <Route path="/view">
        <ViewPage />
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  </Router>  
    </div>
    

  );
}

export default App;
