import React, { useState } from 'react';
import { saveData } from '../api/api';
import Turnstile from 'react-turnstile';


function SavePage() {
  const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY;
  console.log('TURNSTILE_SITE_KEY: ' + TURNSTILE_SITE_KEY);
  let data = { "documentVersion": "1", "deletionToken" : "aaaaaa","formType" : "questions", "uuid" : "000-000000-000-00000-000000" }; 

  const [showChallenge, setShowChallenge] = useState(false);

  function verifyTurnstile(){
    document.getElementById('save-stage1').disabled = true;
    setShowChallenge(true);
  }

  return (
    <div id="save-document">
      <h1>Save Page</h1>
      <button id="save-stage1" onClick={verifyTurnstile}>Save</button>
      <button id="save-stage2" hidden="true">Click to Save to DB</button>
      <div id="save-widget">
      {showChallenge ? <Turnstile sitekey={TURNSTILE_SITE_KEY} onVerify={(token) => {
          console.log('token: ' + token);
           document.getElementById('save-stage1').hidden = true;
           document.getElementById('save-stage2').hidden = false;
           setShowChallenge(false);
           document.getElementById('save-stage2').onclick = function() {
            if(!saveData(token, data)){
              console.log('file save failed');
            };
           };
      }} /> : <></>}
      </div>
    </div>
  );
}

export default SavePage;
